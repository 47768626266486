.container {
  font-family: Quicksand;
  margin: 0 auto;
  height: 100vh;
  overflow-y: auto;
  background: radial-gradient(86.43% 531.61% at 6.91% 76.36%,
      #2c3b7b 0%,
      #4f286b 100%);
}

.head_container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.imgAds {
  width: 40%;
  border-radius: 10px 0px 0px 0px;
}

.fullWidth {
  width: 100%;
}

.sub-text {
  color: #666;
}

.flex {
  display: flex;
}

.pl5 {
  padding-left: 5px;
}

.center {
  justify-content: center;
  display: flex;
  align-items: center;
  padding: 0px 10px;
}

.h2Ads {
  color: #e2017d;
}

.adsButton {
  background: #e2017d;
  color: #fff;
  padding: 15px;
  border-radius: 20px;
  border: none;
  text-transform: uppercase;
}

.line-through {
  padding: 5px 0px 0px 5px;
}

.loader {
  text-align: center;
  height: 80%;
  justify-content: center;
  display: flex;
  align-items: center;
}

.head_container_two {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
}

.first_head,
.second_head {
  white-space: nowrap;
}

@media (min-width: 601px) {
  .head_container_two {
    flex-direction: row;
  }
}

.first_head {
  color: #1f2e64;
  padding-right: 5px;
}

.second_head {
  color: #e2017d;
}

.img_container {
  display: flex;
  align-items: center;
  padding: 0px 19px;
}

.younity_img {
  height: 17.51px;
  top: 35px;
  left: 19px;
  gap: 0px;
}

.header {
  background: #ffffff;
  display: flex;
  height: 50px;
  margin-bottom: 20px;
}

.video-container {
  position: relative;
  text-align: center;
}

.video-container img {
  max-width: 100%;
  height: 30px;
}

.comingsoon {
  justify-content: center;
  display: flex;
  align-items: center;
  font-size: 30px;
  font-weight: 700;
  color: #ffffff;
  height: calc(100vh - 681px) !important;
}

.desc_text_one {
  font-size: 18px;
  font-weight: 700;
  line-height: 25.2px;
  text-align: left;
  color: #e2017d;
}

.desc_text_two {
  font-size: 18px;
  font-weight: 700;
  line-height: 25.2px;
  text-align: left;
  color: #ffffff;
}

.desc_text_three {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  color: #b7b8be;
}

.head {
  color: #ffffff;
  padding: 10px;
  background: linear-gradient(270deg, #e2017d 1.15%, #1f2e64 98.51%);
}

.sound-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  cursor: pointer;
  z-index: 1;
}

.description-div {
  display: flex;
  align-items: center;
  width: 610px;
}

.description-section {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 10px;
}

.profile-image {
  margin-right: 20px;
}

.profile-image img {
  width: 73px;
  height: 73px;
  border-radius: 50%;
}

@media only screen and (max-width: 600px) {
  .react-player {
    max-height: calc(100vh - 420px);
    max-width: calc(100vh - 280px);
    height: calc(100vh - 681px) !important;
  }
}

@media only screen and (min-width: 601px) {
  .react-player {
    display: inline-flex;
    max-height: calc(100vh - 320px);
    max-width: 1060px;
  }
}


@media only screen and (max-width: 600px) {
  .react-players {
    display: inline-flex;
    max-height: calc(100vh - 420px);
    max-width: calc(100vh - 280px);
    height: calc(100vh - 681px) !important;
  }
}

@media only screen and (min-width: 601px) {
  .react-players {
    display: inline-flex;
    max-height: calc(100vh - 320px);
  }
}


.fullscreen-icon {
  position: absolute;
  left: 50%;
  bottom: 10px;
  transform: translateX(-50%);
  cursor: pointer;
  z-index: 1;
}

.fullscreen-icon img {
  width: 30px;
  height: 30px;
}