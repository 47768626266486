.modal-overlay {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-family: Quicksand;
}

.modal-content {
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  max-width: 500px;
  width: 90%;
  border-radius: 10px;
  position: relative;
}

.modal-header {
  position: absolute;
  top: -15px;
  right: -15px;
  background: transparent;
}

.modal-close {
  background: white;
  border: none;
  width: 45px;
  height: 45px;
  font-size: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  color: #666;
  cursor: pointer;
  z-index: 9;
}

.modal-body {
  position: relative;
}